
import {createStore,applyMiddleware,combineReducers} from 'redux';
import thunk from 'redux-thunk'
import reducer from '../reducers/index'
import {
    cartReducer,
    setCartCurrency
  } from "react-shopping-cart";



export const store = createStore( combineReducers({cart: cartReducer,appReducer:reducer}),applyMiddleware(thunk))
store.dispatch(setCartCurrency("AUD"));


export function saveToLocalStorage(key,value){
    localStorage.setItem(key, value);
}

export function deleteFromLocalStorage(key,value){
    localStorage.setItem(key,value)
}

export function getFromLocalStorage(key){
    return localStorage.getItem(key);
}

