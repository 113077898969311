import * as React from 'react';
import withInstagramFeed from 'origen-react-instagram-feed';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import compose from 'recompose/compose';
import EllipsisText from "react-ellipsis-text";
import ListGroup from 'react-bootstrap/ListGroup';
import { theme } from '../theme'
import Media from 'react-bootstrap/Media';

const styles = () => ({
    wrapper: {},
    image: {
        width: '100%',
        height: '100%',
    },
});

export type Props = {
    media?: Array<{
        displayImage: string,
        id?: string,
        postLink?: string,
        accessibilityCaption?: string,
    }>,
    account: string,
    classes: { [$Keys<$Call<typeof styles>>]: string },
    status: 'completed' | 'loading' | 'failed',
};

const InstaGrid = ({ classes, media, account, status }: Props) => {
    const listGroupItemStyle = {
        border: 0,
        borderRadius: 'inherit'
    }
    const listGroupStyle = {
        backgroundColor: theme.colors.textColorLight,
        border: '1px solid ' + theme.colors.borderColor,
        borderRadius: 20,
        boxShadow: "4px 4px 0px 0px #00000040",
    }
    const ellipsisTextStyle =
    {
        fontSize: 12, lineHeight: 0
    }
    //console.log(media)
    return (
        <Grid container spacing={32} className={classes.wrapper}>
            {media &&
                status === 'completed' &&
                media.map(({ displayImage, id, postLink, accessibilityCaption,caption }) => (
                    <>
                        <Grid item xs={12} sm={12} md={12} key={id} style={{padding:10}}>
                            <ButtonBase
                                href={postLink || `https://www.instagram.com/${account}/`}
                            >
                                <img
                                    src={displayImage}
                                    alt={accessibilityCaption || 'Instagram picture'}
                                    className={classes.image}
                                />
                            </ButtonBase>
                                <div style={{ lineHeight: 1.5,fontSize:14,marginTop:10 }}>
                                   {caption}
                                </div>
                            
                        </Grid>
                    </>
                ))}
            {status === 'loading' && <p>loading...</p>}
            {status === 'failed' && <p>Check back later</p>}
        </Grid>
    );
};

InstaGrid.defaultProps = {
    media: undefined,
};

export default compose(
    withInstagramFeed,
    withStyles(styles),
)(InstaGrid);