const { NODE_ENV } = process.env;

const development = {
  BASE_URI: 'https://thekitchaco.com',
};
// TODO: set the production values
const production = {
  BASE_URI: 'https://thekitchaco.com',
};

export default NODE_ENV === 'development' ? development : production;
