import React from 'react';
import './App.css';
import DashboardScreen from './screens/DashboardScreen/';
import {connect} from 'react-redux'




const App = (props) => {

  return (
    <DashboardScreen props={props}></DashboardScreen>
  );
}

function mapStateToProps(state){
  return {
    selectedTab : state.appReducer.selectedTab,
    vendorData:state.appReducer.vendorData,
    location:state.appReducer.vendorData,
    filters:state.appReducer.filters,
    selectedCategory:state.appReducer.selectedCategory,
    loggedIn:state.appReducer.loggedIn,
    smsSent:state.appReducer.smsSent,
    loginModalToggle:state.appReducer.loginModalToggle,
    profileModalToggle:state.appReducer.profileModalToggle,
    phone:state.appReducer.phone,
    userId:state.appReducer.userId,
    token:state.appReducer.token,
    username:state.appReducer.username,
    email:state.appReducer.email,
    userAddress:state.appReducer.userAddress,
    userImg:state.appReducer.userImg,
    userLocation:state.appReducer.userLocation,
    currentLatitude:state.appReducer.currentLatitude,
    currentLongitude:state.appReducer.currentLongitude,
    products:state.appReducer.products,
    paymentStatus:state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(App);
