import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import SideBar from '../UI/SideBar';
import { store, getFromLocalStorage } from '../../store'
import { theme } from '../UI/theme';
import { getSelectedVendor } from '../../actions'
import { Redirect } from 'react-router-dom'
import { updateCartProducts, updateCartTotal, changeTab } from '../../actions';
import LazyLoad from 'react-lazy-load';
import Img from 'react-image'
import './style.css'

import {
    CartComponent,
    ProductComponent,
    CheckoutButtonComponent,
    cartLocalization
} from "react-shopping-cart";
import CustomButton from '../UI/Button';
import { KICTHACO_GALLERY } from '../../assets/Constants';
const { getDefaultLocalization } = cartLocalization;

const iPadCaseLocalization = {
    color: "Color",
    iPadCase: "iPad case",
    red: "Red",
    green: "Green",
    yellow: "Yellow",
    AUD: "$"
};

const iPadPropertiesWithAdditionalCostLocalization = {
    yellow: "Yellow"
};


const localization = {
    AUD: '$',
};



class CartComp extends Component {
    state = {
        selectedVendor: -1,
        productsState: {},
        productsTotalState: 0,
        getCheckoutButtonLocalization: getDefaultLocalization(
            "checkoutButton",
            "en",
            iPadCaseLocalization
        ),

        getCartLocalization: getDefaultLocalization(
            "cart",
            "en",

        )
    }
    componentDidMount() {
        const { selectedVendorData, dispatch } = this.props
        const { appReducer } = store.getState()
        const { products, productsTotal } = appReducer
        const { productsState, productsTotalState } = this.state
        if (this.state.selectedMealCategory == '' && Object.keys(selectedVendorData).length != 0) {

            var meal_categories = [];
            this.setSelectedMealCategory(meal_categories, selectedVendorData, '')
        }
        if (this.isEmpty(productsState)) {
            //// console.log("Hello ", productsState, products)
            this.setState({ productsState: products })
        }
        if (productsTotalState == 0) {
            this.setState({ productsTotalState: productsTotal })
        }


        // var orders=JSON.parse(getFromLocalStorage("orders"))
        // orders.map((order)=>{
        //     //console.log("Cart",order.status)
        //     if(order.status!="Finished"){
        //         dispatch(updateCartProducts(this.state.productsState))
        //         window.location.href="/checkout"
        //     }
        // })



    }
    componentDidUpdate(prevProps, prevState) {
        const { dispatch } = this.props;
        const { appReducer } = store.getState()
        const { products, productsTotal } = appReducer
        // // console.log("Hello",products)
        if (this.isEmpty(products)) {
            dispatch(changeTab('explore'))
            window.location.href = "/"
        }
    }

    isEmpty = obj => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    };

    updateProduct = (key, updatedProduct) => {
        const { productsState } = this.state
        const { appReducer } = store.getState()
        const { products } = appReducer
        const { dispatch } = this.props
        var jsonObj = productsState

        for (var k in jsonObj) {
            if (k == key) {
                jsonObj[k] = updatedProduct;
                break;
            }
        }
        this.setState({ productsState: jsonObj }, () => {
            //console.log("Removed ", this.state.productsState)
            dispatch(updateCartProducts(this.state.productsState))
            dispatch(updateCartTotal(this.calculateCartTotal(this.state.productsState)))

        })

    }

    removeProduct = key => {
        const { productsState } = this.state
        const { appReducer } = store.getState()
        const { products } = appReducer
        const { dispatch } = this.props
        var jsonObj = productsState
        //console.log("Remove ", this.state.productsState)
        delete jsonObj[key];
        this.setState({ productsState: jsonObj }, () => {
            //console.log("Removed ", this.state.productsState)
            dispatch(updateCartProducts(this.state.productsState))
            dispatch(updateCartTotal(this.calculateCartTotal(this.state.productsState)))

        })
    }

    calculateCartTotal = (products) => {
        var price = 0
        for (var key in products) {
            price += Number(products[key].prices.AUD) * Number(products[key].quantity);
            products[key].additionalCost && products[key].additionalCost.map((item)=>{
                var additionalPrice = Object.values(item)[0] * Number(products[key].quantity)
                if(!isNaN(Number(additionalPrice))){
                    price+=Number(additionalPrice)
                }
                
            })
        }
        this.setState({ productsTotalState: price })
        return price;
    }

    render() {
        const { appReducer } = store.getState()
        const { selectedVendorData, products, productsTotal } = appReducer
        const {
            productsState,
            productsTotalState,
            getCheckoutButtonLocalization
        } = this.state
        const {
            updateProduct,
            removeProduct,
            isEmpty,
        } = this;
        const { dispatch } = this.props


        //// console.log("Hello There ",JSON.stringify(productsState))


        const checkoutButtonElement = (
            <CheckoutButtonComponent
                className="cart-checkout-button"
                grandTotal={productsTotalState}
                hidden={isEmpty(productsState) ? true : false}
                checkoutURL="/checkout"
                currency="AUD"
                getLocalization={getCheckoutButtonLocalization}
            />
        );

        const gallery = (
            <div onClick={()=>{dispatch(changeTab('explore'));window.location.href='/';}}>
            <Row style={{marginBottom:10,marginTop:10}}>
                <Col xs={4}>
                    <LazyLoad

                        width={'100%'}
                        height={'100%'}
                        debounce={false}
                        offsetVertical={500}
                    >
                        <Img
                            style={{ width: '100%' }}
                            src={[

                                require('../../assets/img/gallery1.png')
                            ]}

                        />
                    </LazyLoad>
                </Col>
                <Col xs={4}>
                    <LazyLoad

                        width={'100%'}
                        height={'100%'}
                        debounce={false}
                        offsetVertical={500}
                    >
                        <Img
                            style={{ width: '100%' }}
                            src={[
                                require('../../assets/img/gallery2.jpg')
                            ]}

                        />
                    </LazyLoad>
                </Col>
                <Col xs={4}>
                    <LazyLoad

                        width={'100%'}
                        height={'100%'}
                        debounce={false}
                        offsetVertical={500}
                    >
                        <Img
                            style={{ width: '100%' }}
                            src={[
                                require('../../assets/img/gallery3.png')
                            ]}

                        />
                    </LazyLoad>
                </Col>
            </Row>
            <Row style={{marginBottom:10}}>
                <Col xs={4}>
                    <LazyLoad

                        width={'100%'}
                        height={'100%'}
                        debounce={false}
                        offsetVertical={500}
                    >
                        <Img
                            style={{ width: '100%' }}
                            src={[

                                require('../../assets/img/gallery4.png')
                            ]}

                        />
                    </LazyLoad>
                </Col>
                <Col xs={4}>
                    <LazyLoad

                        width={'100%'}
                        height={'100%'}
                        debounce={false}
                        offsetVertical={500}
                    >
                        <Img
                            style={{ width: '100%' }}
                            src={[
                                require('../../assets/img/gallery5.png')
                            ]}

                        />
                    </LazyLoad>
                </Col>
                <Col xs={4}>
                    <LazyLoad

                        width={'100%'}
                        height={'100%'}
                        debounce={false}
                        offsetVertical={500}
                    >
                        <Img
                            style={{ width: '100%' }}
                            src={[
                                require('../../assets/img/gallery6.png')
                            ]}

                        />
                    </LazyLoad>
                </Col>
            </Row>
            <Row style={{marginBottom:10}}>
                <Col xs={4}>
                    <LazyLoad

                        width={'100%'}
                        height={'100%'}
                        debounce={false}
                        offsetVertical={500}
                    >
                        <Img
                            style={{ width: '100%' }}
                            src={[

                                require('../../assets/img/gallery7.png')
                            ]}

                        />
                    </LazyLoad>
                </Col>
                <Col xs={4}>
                    <LazyLoad

                        width={'100%'}
                        height={'100%'}
                        debounce={false}
                        offsetVertical={500}
                    >
                        <Img
                            style={{ width: '100%' }}
                            src={[
                                require('../../assets/img/gallery8.png')
                            ]}

                        />
                    </LazyLoad>
                </Col>
                <Col xs={4}>
                    <LazyLoad

                        width={'100%'}
                        height={'100%'}
                        debounce={false}
                        offsetVertical={500}
                    >
                        <Img
                            style={{ width: '100%' }}
                            src={[
                                require('../../assets/img/gallery9.png')
                            ]}

                        />
                    </LazyLoad>
                </Col>
            </Row>
            <Row style={{marginBottom:10}}>
                <Col xs={4}>
                    <LazyLoad

                        width={'100%'}
                        height={'100%'}
                        debounce={false}
                        offsetVertical={500}
                    >
                        <Img
                            style={{ width: '100%' }}
                            src={[

                                require('../../assets/img/gallery10.png')
                            ]}

                        />
                    </LazyLoad>
                </Col>
                <Col xs={4}>
                    <LazyLoad

                        width={'100%'}
                        height={'100%'}
                        debounce={false}
                        offsetVertical={500}
                    >
                        <Img
                            style={{ width: '100%' }}
                            src={[
                                require('../../assets/img/gallery11.png')
                            ]}

                        />
                    </LazyLoad>
                </Col>
                <Col xs={4}>
                    <LazyLoad

                        width={'100%'}
                        height={'100%'}
                        debounce={false}
                        offsetVertical={500}
                    >
                        <Img
                            style={{ width: '100%' }}
                            src={[
                                require('../../assets/img/gallery12.png')
                            ]}

                        />
                    </LazyLoad>
                </Col>
            </Row>
            </div>
        )



        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col xs={3} style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", transition: "0.3s", height: "100vh", paddingTop: 15 }}>
                            <Row>
                                <Col xs={12}>
                                    <Card style={{ width: '100%' }}>
                                        <Card.Body>
                                            <Card.Title>Craving something else?</Card.Title>
                                            <Card.Text>
                                                You can add more dishes to your cart from different restaurants without any additional delivery charges.
                                            </Card.Text>
                                            <CustomButton
                                                backgroundColor={theme.colors.primaryColor}
                                                textColor={theme.colors.textColorLight}
                                                borderRadius={20}
                                                isProfilePic={false}
                                                buttonText={'Add from other restaurant'}
                                                handleButtonClick={() => { dispatch(changeTab('explore')); window.location.href = "/" }}
                                            ></CustomButton>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            {gallery}
                        </Col>
                        <Col xs={9} className="cart-component-container" style={{ boxShadow: "0 4px 8px -10px rgba(0,0,0,0.2)", transition: "0.3s", height: "100vh", paddingTop: 10 }} >

                            <CartComponent

                                hideHeader={true}
                                products={
                                    productsState
                                    // Provide your own product's Object(Look at Products)
                                }
                                onUpdateProduct={
                                    updateProduct
                                    // Update something
                                }
                                getLocalization={getDefaultLocalization('cart', 'en', localization)}

                                currency="AUD"
                                onRemoveProduct={
                                    removeProduct
                                    // Remove something
                                }
                                checkoutButton={checkoutButtonElement}
                                isCartEmpty={false}
                                

                            />

                        </Col>
                    </Row>
                </Container>
            </div >
        );
    }
}

export default CartComp;