import axios from 'axios';


import config from './apiConfig';

const instance = axios.create({
  baseURL: `${config.BASE_URI}`,
});

export const applyToken = token => {
  instance.defaults.headers.common.Authorization = `Token ${token}`;
  
};

export const clearToken = () => {
  instance.defaults.headers.common.Authorization = null;
};

instance.interceptors.response.use(
  response => response,
  error => {
    if (!error.response && error.message === 'Network Error') {
      console.log('Connection error!Please check your internet connection and try again.');
    }
    return Promise.reject(error);
  }
);



export default instance;
